.footer__section{
    width: 100%;
    background: #000000;

}
.footer__width{
    width: 80%;
    margin: auto;
    text-align: left;
    padding-top: 50px;
    padding-bottom: 50px;

}
.footer_col{
    flex-basis:33%;
    padding: 20px 12px;
    box-sizing: border-box;
    transition: 0.5s;
}
.footer_col h3{
    font-family: 'Nexa2',sans-serif;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 700;


}
.footer_col p{
    margin-top: 5%;
    font-family: 'Nexa2',sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 300;
    opacity: 0.6;
}
.link__footer{
    font-family: 'Nexa2',sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #F5F5F5 !important;
    display: block;
    margin-top: 9%; 
    opacity: 0.6;
}
.contact_detil{
    display: flex;
    align-items:center;
    margin-top: 8%;
}
.contact_detil i{
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 300;  
    opacity: 0.6;
}
.contact_detil span{
    font-family: 'Nexa2',sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 300;
    opacity: 0.6;
    margin-left: 4%;   
}
.social__media{
    margin-top: 5%;
}
.social__media i{
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 300;
    padding-right: 12px;
   
}
#tet{
    text-align: left;
    margin-left: 30%;
 }
@media (max-width:900px) {
 .row{
    flex-direction: column;
 }   
 #tet{
    text-align: left;
    margin-left: 0%;
 }
}