.faq__sec{
    width: 80%;
    margin: auto;
}

.faq__sec h2{
    font-family: Nunito;
font-size: 36px;
font-weight: 700;
line-height: 49.1px;
text-align: center;
color: aqua;
}
.faq__sec p{
    font-family: Nunito;
font-size: 18px;
font-weight: 300;
line-height: 24.55px;
text-align: left;
color: #fff;
}
.privacy__sec{
width: 80%;
margin: auto;
}
.privacy__sec h2{
    font-family: Nunito;
    font-size: 36px;
    font-weight: 700;
    line-height: 49.1px;
    color: #fff;
    
    opacity: 0.9;
}
.privacy__sec p{
    font-family: Lato;
font-size: 18px;
font-weight: normal;
line-height: 22px;
text-align: justified;
color: #fff;
margin-top: 6px;

opacity: 0.8;
}
.privacy__sec ul{
    font-family: Lato;
font-size: 20px;
font-weight: 300;
line-height: 22px;
text-align: justified;
color: #fff;
margin-top: 6px;

opacity: 0.8;
}

.r__colt h2{
    font-family: Anek Telugu;
font-size: 48px;
font-weight: 600;
line-height: 56px;
text-align: left;

}