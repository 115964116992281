.feature__main{
    width: 80%;
    margin: auto;
}
.feature__main h2{
    font-family: Lato;
font-size: 54px;
font-weight: 400;
line-height: 76.8px;
letter-spacing: -0.025em;
text-align: center;
color: #fff;

}
.feature__row{
    display: flex;
justify-content: space-between;
}
.feature_col{
    flex-basis: 49%;
}
.feature_col  h2{
    font-family: Lato;
font-size: 36px;
font-weight: 400;
line-height: 43.2px;
text-align: left;
color: rgba(0, 208, 148, 1);

}
.feature_col p{
    font-family: Lato;
font-size: 22px;
font-weight: 300;
line-height: 26.4px;
text-align: left;
color: #fff;
margin-top: 10px;
opacity: 0.8;
}
.feature__col2{
    flex-basis: 33%;
text-align: center;

}
.feature__col2 img{
width: 15%;
height:9vh;
}
.feature__col2 h3{
    font-family: Lato;
    font-size: 30px;
    font-weight: 400;
    line-height: 33.2px;
    text-align: center;
    margin-top: 15px;
    color: rgba(0, 208, 148, 1);
}
.feature__col2 p{
    font-family: Lato;
    font-size: 22px;
    font-weight: 300;
    line-height: 26.4px;
    text-align: left;
    color: #fff;
    margin-top: 10px;
    opacity: 0.8;
}
.feature__row2{
    display: flex;
    justify-content: space-between;
}
.feature__col3{
    flex-basis: 49%;
}
.feature__col3 img{
    width: 100%;
}
.feature__col3 p{
    font-family: Lato;
    font-size: 22px;
    font-weight: 300;
    line-height: 26.4px;
    text-align: left;
    color: #fff;
    margin-top: 10px;
    opacity: 0.8;
}
.feature__col3 h3{
    font-family: Lato;
    font-size: 30px;
    font-weight: 400;
    line-height: 33.2px;
    text-align: left;
    margin-top: 15px;
    color: rgba(0, 208, 148, 1);
}
@media (max-width:900px) {
    .feature__row2,.feature__row{
        flex-direction: column;
    }
}