.contact__section{
    width: 80%;
    margin: auto;
    margin-top: 3%;
}
.contact__row{
    display: flex;
    justify-content: space-between;

}


.contact__col1{
    flex-basis:70%;
}
.contact__col1 h2{
    font-family: Lato;
font-size: 45px;
font-weight: 700;
line-height: 54px;
text-align: left;
color: #fff;
}
.contact__col2{
  padding: 20px;
    flex-basis:26%;
    background: #fff;
    height: 70vh;
 
}
.contact__col2 p{
    font-family: Nunito;
font-size: 18px;
font-weight: 400;
line-height: 30px;
text-align: left;

}
.contact__col2 div{

padding: 10px;
gap: 10px;
border-radius: 5px 0px 0px 0px;

background: rgba(0, 208, 148, 1);
font-family: Nunito Sans;
font-size: 16px;
font-weight: 700;
line-height: 21.82px;
text-align: leeft;
color: #fff;
}
.contact__col2 h3{
    font-family: Lato;
font-size: 18px;
font-weight: 500;
line-height: 21.6px;
text-align: left;
color: black;
}
.form__inp__row{
    display: flex;
    justify-content: space-between;
}

.form__col__inp{
    flex-basis: 49%;
}
.form__col__inp input{
    width: 100%;
    padding: 10px;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
   background: transparent; 
   color: #fff;
   border: 1px solid #fff;
}
.contact__col1 input{
    border: 1px solid #fff;
    color: #fff;
    width: 100%;
    padding: 10px;
    font-family: Nunito;
font-size: 16px;
font-weight: 400;
line-height: 28px;
text-align: left;
background: transparent; 
    
}
#bttn{
    width: Hug (213px)px;
height: Hug (50px)px;
padding: 15px 50px 15px 50px;
gap: 10px;
border-radius: 8px ;

background: rgba(0, 208, 148, 1);
font-family: Nunito;
font-size: 20px;
font-weight: 500;
line-height: 27.28px;
text-align: center;
color: #fff;
margin-left: 80%;
}
@media (max-width:900px) {
 .contact__row{
    flex-direction: column;
 }
 #bttn{
width: 100%;
margin-left: 0%;
}   
}