.ab_div1{
    width: 80%;
    margin: auto;

}

.ab__rowa{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.col__a{
    flex-basis: 47%;
}
.col__a h3{
    font-family: Nunito;
font-size: 32px;
font-weight: 600;
line-height: 24.55px;
text-align: left;
color: aqua;
}
.col__a h2{
    font-family: Nunito;
font-size: 6px;
font-weight: 700;
line-height: 49.1px;
text-align: left;
color: aqua;
}
.col__a p{
    font-family: Nunito;
font-size: 20px;
font-weight: 400;
line-height: 36.94px;
text-align: left;
color: rgba(255, 255, 255, 1);

}
.col__a img{
    width: 100%;
}

.ab_div1 h2{
    font-family: Nunito;
font-size: 36px;
font-weight: 700;
line-height: 49.1px;
text-align: left;
color: aqua;
}
.cril{
    width: 37%;
    margin: auto;
    padding: 40px;

border-width: 1px, 0px, 0px, 1px;
border-left:solid 1px ;
border-top: solid 1px;

border-color: rgba(0, 208, 148, 1);


}
.cril h2{
    font-family: Nunito;
font-size: 30px;
font-weight: 700;
line-height: 49.1px;
text-align: left;
color: aqua;
}
.cril p{
    font-family: Nunito;
font-size: 18px;
font-weight: 400;
line-height: 36.94px;
text-align: left;
color: #fff;
}
.cirl1 {
    width: 250px;
height: 250px;
top: 401px;
left: 18px;
gap: 0px;

background-color: aqua;
border-radius: 800px;
text-align: center;

}
.id_cir{
    width: 20%;
    margin: auto;
}
.id_cir2{
    width: 100%;

}
.rowww{
    display: flex;
    justify-content: space-between;
}
.col__circle{
flex-basis:49%;
}
.cirl1 h2{
    font-family: Nunito;
font-size: 30px;
font-weight: 500;
line-height: 49.1px;
text-align: center;
color: #fff;
padding-top: 100px;
}
#mg{
    margin-left: 50%;
}
.crila{
    width: 100%;

    padding: 40px;
border-width: 1px, 0px, 0px, 1px;
border-left:solid 1px ;
border-top: solid 1px;

border-color: rgba(0, 208, 148, 1);


}

@media (max-width:900px) {
    .ab__rowa{
        flex-direction: column;
    }
    .col__a{
        margin-top: 20px;
    }
    .cril,.crila{
        width: 100%;
    }
    .id_cir{
        width: 100%;
    margin-left: 20%;
    }
    .rowww{
        flex-direction: column;
    }
    .col__circle{
        margin-left: 20%;
        margin-top: 10px;
    }
    #mg{
        margin-left: 0%;
    }
    .crila{
        width: 120%;
    margin-left: -20%;
        padding: 40px;

    border-width: 1px, 0px, 0px, 1px;
    border-left:solid 1px ;
    border-top: solid 1px;
    
    border-color: rgba(0, 208, 148, 1);
    
    
    }
}