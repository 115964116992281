.blog__seca{
    width: 80%;
    margin: auto;
}
.blog_row{
    display: flex;
    justify-content: space-between;
    

}
.blog__col{
    flex-basis: 33%;
}
.blog__col img{
    width: 100%;
}
.blog__col h2{
    font-family: Montserrat;
font-size: 18px;
font-weight: 700;
line-height: 25.2px;
text-align: left;
color: #fff;
}
.blog__col p{
    font-family: Open Sans;
font-size: 14px;
font-weight: 400;
line-height: 22.4px;
text-align: left;
color: #fff;
}


.blg_head{

}
.blg_head img{
    width: 100%;
}
.blg_head h2{
    font-family: Lato;
font-size: 24px;
font-weight: 600;
line-height: 22px;
text-align: justified;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #fff;
margin-top: 12px;
margin-bottom: 12px;
}
.blg_head p{
    font-family: Lato;
font-size: 20px;
font-weight: 300;
line-height: 25px;
text-align: justified;
margin-bottom: 12px;
margin-bottom: 12px;

text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #fff;
}
.blg_head h3{
    font-family: Lato;
font-size: 20px;
font-weight: 400;
line-height: 22px;
text-align: justified;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #fff;
margin-top: 12px;
margin-bottom: 12px;

}
.blogaa_row{
    display: flex;
    justify-content: space-between;

margin: auto; 
}
.blogaa_cola{
    flex-basis: 75%;
    border-radius: 5px;
    padding: 10px;
}
.blogaa_colb{
    flex-basis: 24%;
    border-radius: 5px;

    padding: 10px;
}
#mgg{
    height: 100vh;
}
@media (max-width:900px) {
    .blog_row{
        flex-direction: column;
    }   
    #mgg{
        height: 60vh;
    }   
}