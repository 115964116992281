.testio{
    width: 80%;
margin:auto;
}
.test__row{
    display: flex;
    justify-content: space-between;
}
.test__col{

flex-basis: 49%;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);

box-shadow: 0px 0.6px 0.6px -1.25px rgba(0, 0, 0, 0.18);

box-shadow: 0px 2.29px 2.29px -2.5px rgba(0, 0, 0, 0.16);

box-shadow: 0px 10px 10px -3.75px rgba(0, 0, 0, 0.06);
background: #fff;
border-radius: 10px;
padding: 30px;
height: 25vh;
}
.test__col p{
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 22.4px;
text-align: left;
color: black;
}
.test_row2{
    display: flex;
    justify-content: space-between;
}
.test__col2a{
    flex-basis: 49%;
}
.test__col2a h3,.r__colt h2{
    font-family: Inter;
font-size: 26px;
font-weight: 700;
line-height: 22.4px;
text-align: left;
color: aqua;
}
.test__col2a p,.r__colt p{
    font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.8px;
text-align: left;
color: rgba(74, 103, 120, 1);

}
.test__col2a img{
    width: 20%;
    margin-left: 80%;
}
.r__trade{
    display: flex;

    justify-content: space-between;
}
.r__colt{
    flex-basis: 49%;
}
@media (max-width:900px) {
    .test__row,.test__row2,.r__trade{
    flex-direction: column;
    }
    
}