@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100..800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100..800&display=swap');
  *{
    margin: 0;
    padding: 0;
}
body{
    
    overflow-X: hidden;
    background: rgba(0, 21, 15, 1);

}
html {
    overflow-x: hidden;
  }
  
  body {
    position: relative;
    overflow-x: hidden;

  }
  hr {
    border: 1px solid rgba(255, 255, 255, 1)  }
.Top__up{
color: #000000;
position: fixed;
margin-top: 80vh;
background-color: #FFD200;
margin-left: 90%;
}
.header_top{
    height: 100vh;
    background-image: url(./Tr1.png);
    background-position:center ;
    background-size: cover;
    opacity: 1;

}
.main__t{
    width: 80%;
    margin: auto;
}

.row_t{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding-top: 5%;
    }
.t_col{
    flex-basis:48%;

}
.t_col h1{
    font-family: Lato;
font-size: 60px;
font-weight: 600;
line-height: 48px;
letter-spacing: -0.5px;
text-align: left;
color: #fff;
margin-bottom: 20px;
}
.t_col p{
    font-family: Lato;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

color: rgba(255, 255, 255, 1);

}
.t_col img{
    width: 100%;
}
.t_col button{

height: Fixed (60px)px;
padding: 16px 24px 16px 24px;
gap: 8px;
border-radius: 8px 8px 8px 8px;

background: rgba(0, 208, 148, 1);
font-family: sans-serif;
          border-radius: 6px;
          background: #29a544;
          color: white;
          box-shadow: 1px 1px 3px 0 rgba(0,0,0,.03);
          font-size: 18px;
          font-weight: 700;
          border: none;
          cursor: pointer;
          
}

.row_t2{
    display: flex;
    justify-content: space-between;
    align-items:center; 
  
    }
.col2_t{
    flex-basis:49%;

}
.col2_t img{
    width: 100%;
}
.col2_t h2{
    font-family: Anek Telugu;
font-size: 48px;
font-weight: 600;
line-height: 56px;
text-align: left;
color: #fff;
}
#fntt{
    font-family: Lato;
    font-size: 55px;
    font-weight: 600;
    line-height: 68px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #fff;
    
}
.col2_t h2{
    font-family: Anek Telugu;
font-size: 48px;
font-weight: 600;
line-height: 56px;
text-align: left;

}
.row_t3{
    display: flex;
    justify-content: space-between;
    align-items:flex-start; 
  margin-top: 5%;
    }
.col_t3a{
    flex-basis:18%;

}
.col_t3a img{
    width: 60%;
}

.col_t3b{
    flex-basis:82%;

}
.col_t3b h3{
    font-family: Anek Telugu;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
 color: #fff;    
}
.col_t3b p{

font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

}
#ft3{
    font-family: Lato;
font-size: 32px;
font-weight: 700;
line-height: 48px;
text-align: left;
color: #fff;

}
#ar{
    width: 20px;
    height: 24px;
    top: 723px;
    left: 622px;
    gap: 0px;
    rotate: -90 deg;
    
color: #fff;
}
#fttt{

    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
        color: #fff;
    }
.sec3_div{
    width: 80%;
    margin: auto;
}
.sec3_div h2{
    font-family: Anek Telugu;
font-size: 48px;
font-weight: 600;
line-height: 56px;
text-align: center;
color: #fff;
}
.sec3_div p{

font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;

}
.sec3_row,.sec4_row{
display: flex;
align-items: center;
justify-content: space-between;
}
.sec_col3{
flex-basis: 33%;
width: Fill (374px)px;
height: 65vh;
padding: 40px 32px 40px 32px;
gap: 32px;
border-radius: 16px;

background: rgba(33, 52, 47, 1);
text-align: center;
}
.sec_col3 img{
    width: 50%;
    text-align: center;
}
.sec_col3 h3,.bx4a h3{

font-family: Anek Telugu;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: y;
color: #fff;
}
.sec_col3 p{

font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;
color: #fff;
}
.sec4{
    background: rgb(2, 30, 22);
   padding-top: 40px;
   padding-bottom: 40px; 
}
.sec4_div{
    width: 70%;
    margin: auto;
}
.sec4_div h2{
    font-family: Anek Telugu;
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    text-align: center;
    color: #fff;
}
.sec4_div p,.bx4a p{
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    
}

.sec4_col{
    flex-basis: 33%;
    width: Hug (336px)px;

padding: 32px 24px 32px 24px;
gap: 40px;
border-radius: 16px ;
margin-left: 34%;
background: rgba(33, 52, 47, 1);
box-shadow: 0px 16px 40px 0px rgba(26, 64, 137, 0.06);

}

.sec4_col h2{
    font-family: Anek Telugu;
font-size: 20px;
font-weight: 600;
line-height: 32px;
letter-spacing: 0.04em;
text-align: left;

}
.sec4_col h3{
    font-family: Anek Telugu;
font-size: 22px;
font-weight: 600;
line-height: 40px;
letter-spacing: 0.25px;
text-align: left;
color: #fff;

}
#num{
color: rgba(0, 208, 148, 1);
font-family: Open Sans;
font-size: 28px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.15000000596046448px;
text-align: left;

}
.sec4_row2{
    justify-content: space-between;
    display: flex;
}
.sec4_row2_cola
{
    flex-basis: 1%;

}
.sec4_row2_cola img{
    display: none;
}
.sec4_row2_colb{
    flex-basis: 99%;
    height: 25vh;
}
.sec4_col button,.bx4a button{

font-family: Open Sans;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 1px;
text-align: center;
width: 100%;
padding: 8px;
gap: 8px;
border-radius: 6px ;
border: 1px solid white;
background: transparent;
color: #fff;
}
.sec4a{
width: 80%;
margin: auto;
background: rgb(2, 30, 22);
  
text-align: center;
}

.bx4a{
    margin-left: 35%;
text-align: center;
width: 30%;
padding: 24px;
gap: 40px;
border-radius: 16px ;

background: rgba(33, 52, 47, 1);
box-shadow: 0px 16px 40px 0px rgba(26, 64, 137, 0.06);


}

.sec4a h2{
    font-family: Anek Telugu;
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    text-align: center;
    color: #fff;
}
.sec5{
    background: rgba(0, 21, 15, 1);

}
.sec_div5{
    width: 80%;
margin: auto;
}
.sec_div5 h2{

font-family: Anek Telugu;
font-size: 48px;
font-weight: 600;
line-height: 56px;
text-align: center;
color: #fff;
}
.sec4_row2_colb p{
    text-align: left;
}
.row__sec5{
    display: flex;
 }
.col___sec5{
    flex-basis: 28%;
    width: Fixed (280px)px;
height: Hug (176px)px;
top: 104px;
left: 182px;
padding: 24px ;
gap: 16px;


background: rgba(33, 52, 47, 1);

}
.col___sec5 h3{
color: #fff;
font-family: Anek Telugu;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: center;

}
.col___sec5 p{

font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;

}
.sec__div6{
    margin: auto;
    width: 80%;
}
.sec__div6 h2{

font-family: Anek Telugu;
font-size: 48px;
font-weight: 600;
line-height: 56px;
text-align: center;
color: #fff;
}
.sec__row6{
display: flex;
justify-content: space-between;
}

.sec6__col{
flex-basis: 24%;
    width: 274px;
    height: 360px;
    gap: 0px;
    border-radius: 8px 8px 0px 0px;
     background-image: url(./Media-min.png);    

}
.card_bx{
    width: Fixed (274px)px;
height: Hug (126px)px;
padding: 26px ;
gap: 16px;
border-radius: 16px 16px 0px 0px;
background: rgba(33, 52, 47, 1);

margin-top: 265px;

}
.card_bx h3{

font-family: Anek Telugu;
font-size: 20px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.15000000596046448px;
text-align: center;
color: #fff;
}
.card_bx p{
    color: rgba(197, 204, 209, 1);

font-family: Open Sans;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.25px;
text-align: center;

}
.sec__8{

}
.div__8{
    width: 80%;
    margin: auto;
}
.div__8 h2{

font-family: Anek Telugu;
font-size: 48px;
font-weight: 600;
line-height: 56px;
text-align: center;
color: #fff;
}
.row__sec8{
    display: flex;
    justify-content: space-between;
    
}
.col__sec8{
    flex-basis: 49%;
}
details{
    width: Fill (573px)px;
    height: Hug (128px)px;
    padding: 16px;
    gap: 2px;
    border-radius: 16px ;
   
color: #fff;    
background: #0F231D;
    font-family: Anek Telugu;
font-size: 22px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.15000000596046448px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }
  details p{
    color: #fff;
opacity: 0.5;
font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }
  
  summary {
    padding: .5em 1.3rem;
    list-style: none;
    display: flex;
    justify-content: space-between;  
    transition: height 3s ease;
    
  }
  
  summary::-webkit-details-marker {
    display: none;
  }
  
  summary:after{
    content: "\002B";
    margin-bottom: 3px;
  }
  
  details[open] summary {
      border-bottom: 1px solid #aaa;
      margin-bottom: .5em;
  }
  
  details[open] summary:after{
    content: "\00D7";
  }
  
  details[open] div{
    padding: .5em 1em;
    font-weight: 100;
    
  }


  .div__9{
    width: 80%;
    margin: auto;
  }
.div__9 h2{
    font-family: Anek Telugu;
font-size: 48px;
font-weight: 600;
line-height: 56px;
text-align: center;
color: #fff;
}
  .sec__row9{
    display: flex;
    justify-content: space-between;
  }
  .col__sec9{
    flex-basis: 49%;
    width: Fill (569px)px;
height: Fill (574px)px;
padding: 32px 32px 40px 32px;
gap: 32px;
border-radius: 16px 16px 0px 0px;

background: rgba(33, 52, 47, 1);
box-shadow: 0px 16px 48px 0px rgba(6, 35, 63, 0.03);

box-shadow: 0px -16px 48px 0px rgba(6, 35, 63, 0.03);

  
}
.col__sec9 h3{
color: #fff;
font-family: Anek Telugu;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
margin-top: 20px;

}
.col__sec9 p{
   color: rgba(255, 255, 255, 0.6);

font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

}
.col__sec9 a{
    width: Hug (109.49px)px;
height: Fixed (32px)px;
gap: 12px;
border: 0px 0px 1.5px 0px;

border-bottom: 1.5px solid rgba(0, 208, 148, 1);
color: aqua;
}
.col__sec9 img{
    width: 100%;
}

.div__9a{
    width: 70%;
    margin: auto;
    border-radius: 24px;
    background: rgba(0, 208, 148, 1);
  padding: 20px;
}
.div__9__row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.div__9a__col{
    flex-basis: 49%;

}
.div__9a__col img{
    width: 70%;
    margin-left: 15%;
}
.div__9a__col h2{
    font-family: Anek Telugu;
    font-size: 38px;
    font-weight: 600;
    line-height: 56px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
        
}
.div__9a__col p{
color: #0C263A;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    

}
.div__9a__col button{
    width: Hug (124px)px;
height: Fixed (56px)px;
padding: 10px 32px 10px 32px;
gap: 8px;
margin-left: 10px;
border-radius: 8px ;
background: #0A4FD5;
color: #fff;
border: none;
font-family: Open Sans;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 1px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.div__9a__col input ::-ms-value{
    
}
.div__9a__col input{
    border: 2px solid;
    width: Fill (342px)px;
    height: Fixed (56px)px;
    padding: 14px 20px 14px 20px;
    gap: 8px;
    border-radius: 8px;
    border: 2px;
    color:"#0A4FD5";
border-image-source: linear-gradient(150.39deg, rgba(229, 237, 248, 0.47) 15.02%, #EDF2F8 99.59%);


}

@media (max-width:900px) {
#lineee{
    display: none;
}
    .row_t{
    flex-direction: column;
}
.t_col img{
    display: none;
}
.row_t2 {
    flex-direction: column;
}
#pic__hid{
display: none;
}
.sec3_row{
    flex-direction: column;
}
.sec_col3{
    margin-top: 20px;
}
.sec4_row{
    flex-direction: column;
}
.sec4_col{
    width: 100%;
    margin-top: 20px;
    margin-left: 0%;
}
.row__sec5{
    flex-direction: column;
}
.sec__row6{
    flex-direction: column;
}
.sec6__col{
    margin-left: 60px;
    flex-basis: 100%;
        width: 274px;
        height: 360px;
        gap: 0px;
        border-radius: 8px 8px 0px 0px;
  
        background-image: url(./Media-min.png);    
    margin-top: 20px;
    }
.row__sec8{
    flex-direction: column;
}
.sec__row9{
    flex-direction: column;
}
.col__sec9{
    margin-top: 20px;
}
.div__9__row{
    flex-direction: column;
}
.div__9a{
    width: 100%;
    margin-top: 20px;
}
.t_col h1{
 margin-top: 40px;
}
.div__9a__col img{
    width: 90%;
    margin-left: 0%;
}
}
